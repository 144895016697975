import geral from './geral.config';

const configs = {
    geral: {
        ...geral
    },
    titles: {
        SECTOR: 'Esqueceu a senha?'
    },
    fields: {
        email: {
            LABEL: 'Email'
        }
    },
    buttons: {
        SUBMIT: 'Recuperar senha'
    },
    messages: {
        email: {
            alerts: {
                MANDATORY_EMAIL: 'Email obrigatório!'
            },
            errors: {
                CHECK_EMAIL: 'Preencha o campo email!',
                EMAIL_VALID: 'Insira um email válido!'
            }
        }
    }
};

export default configs;
