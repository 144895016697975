<template>
    <v-container fill-height>
        <v-layout justify-center align-center>
            <v-flex xs12 sm6 md4 lg3 xl3>
                <SanackBar
                    :snackActivated="snackActivated"
                    :success="snackSuccess"
                    :snackMessage="snackMessage"
                    @closeSnackBar="closeSnack()" />

                <v-card class="elevation-12">
                    <v-toolbar color="primary" dark>
                        <v-toolbar-title>{{ titles.toolbar }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-progress-circular
                            indeterminate
                            color="white"
                            width="2"
                            v-show="loadingActivated">
                        </v-progress-circular>
                    </v-toolbar>

                    <v-card-text v-if="submitedForm">
                        <v-container class="box-submited">
                            <h1 class="info-title">Senha enviada!</h1>
                            <p class="info-msg">
                                A senha foi enviada para o email
                                <strong>{{ user.email }}</strong
                                >.
                            </p>
                        </v-container>
                    </v-card-text>

                    <v-card-text v-else>
                        <v-form>
                            <v-container>
                                <v-row>
                                    <v-col>
                                        <v-container class="field">
                                            <CloseField
                                                :name="'email'"
                                                :activated="
                                                    getStatusFieldComputed('email')
                                                "
                                                @closeSpeficField="closeField($event)" />

                                            <v-text-field
                                                :label="getLabel('email')"
                                                :disabled="loadingActivated"
                                                type="email"
                                                ref="email"
                                                :background-color="fields.email.bgColor"
                                                filled
                                                rounded
                                                dense
                                                v-model.trim="$v.user.email.$model"
                                                :error-messages="emailErrorsComputed"
                                                :success="emailSuccessComputed"
                                                @select="activeField('email')"
                                                @focus="activeField('email')">
                                            </v-text-field>
                                        </v-container>

                                        <v-container class="field">
                                            <SubmitFormButton
                                                :title="titles.submitButton"
                                                :disabled="loadingActivated"
                                                @submitForm="submitForm()" />
                                        </v-container>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card-text>
                </v-card>
                <v-container class="box">
                    <GoTo
                        :title="titles.goToSignIn"
                        :icon="'person'"
                        :route="'signin'"
                        :disabled="loadingActivated" />
                </v-container>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import { required, email } from 'vuelidate/lib/validators';
    import SanackBar from '@/components/snack/SanackBar.vue';
    import CloseField from '../components/geral/CloseIconField.vue';
    import { isAmbientGeralTests } from '@/configs/global.config';
    import geralConfig from '../configs/geral.config';
    import forgotPasswordConfig from '../configs/forgot.password.config';
    import userMock from '../mocks/signin.mock';
    import GoTo from '../components/geral/GoToButton.vue';
    import SubmitFormButton from '../components/geral/SubmitFormButton.vue';

    export default {
        name: 'ForgotPassword',
        components: { SanackBar, CloseField, GoTo, SubmitFormButton },
        data() {
            return {
                isAmbientLocalTests: true,
                loadingActivated: false,
                fieldNameActivated: null,
                fieldSelected: null,
                submitedForm: false,
                snackActivated: false,
                snackSuccess: false,
                snackMessage: null,
                user: {
                    email: null
                },
                titles: {
                    toolbar: forgotPasswordConfig.titles.SECTOR,
                    goToSignIn: forgotPasswordConfig.geral.buttons.SIGNIN,
                    submitButton: forgotPasswordConfig.buttons.SUBMIT
                },
                configs: {
                    fields: {
                        bgColor: {
                            error: '#ffffcc'
                        }
                    }
                },
                fields: {
                    email: {
                        bgColor: null
                    }
                }
            };
        },
        mounted() {
            this.populateGeral();

            this.activeField('email');

            this.changeColorField('email');

            this.focusField('email');

            this.checkStateFields();
        },
        watch: {
            user: {
                handler(e) {
                    this.monitoreUserFieldsComputed(e);
                },
                deep: true
            }
        },
        computed: {
            monitoreUserFieldsComputed() {
                return (e) => {
                    for (const key of Object.keys(this.fields)) {
                        if (e[key] && e[key].length) {
                            this.fields[key].btClose = true;
                        } else {
                            this.fields[key].btClose = false;
                        }
                    }
                };
            },
            getStatusFieldComputed() {
                return (nameField) => {
                    return this.fields[nameField].btClose;
                };
            },
            emailErrorsComputed() {
                const emailField = this.$v.user.email;

                if (!emailField.$dirty) {
                    return [];
                }

                const errors = [];

                !emailField.required &&
                    errors.push(
                        forgotPasswordConfig.messages.email.alerts.MANDATORY_EMAIL
                    );

                !emailField.email &&
                    errors.push(forgotPasswordConfig.messages.email.errors.EMAIL_VALID);

                return errors;
            },
            emailSuccessComputed() {
                const emailField = this.$v.user.email;

                this.toggleFieldBgColor('email', emailField);

                return !emailField.$invalid;
            }
        },
        validations() {
            const validations = {
                user: {
                    email: {
                        required,
                        email
                    }
                }
            };

            return {
                user: validations.user
            };
        },
        methods: {
            populateMock() {
                this.users = Object.assign(this.user, userMock);
            },
            populateGeral() {
                if (isAmbientGeralTests && this.isAmbientLocalTests) {
                    this.populateMock();
                }
            },
            checkStateFields() {
                for (const key of Object.keys(this.fields)) {
                    if (this.user[key] && this.user[key].length) {
                        this.toggleStatusActivedField(key);
                    }
                }
            },
            toggleStatusActivedField(nameField) {
                this.fields[nameField].btClose = !this.fields[nameField].btClose;
            },
            getLabel(nameField) {
                return forgotPasswordConfig.fields[nameField].LABEL;
            },
            activeField(nameField) {
                this.fieldSelected = nameField;
            },
            closeField(nameField) {
                this.user[nameField] = null;

                this.activeField(nameField);

                this.focusField(nameField);

                this.fields[nameField].btClose = false;
            },
            closeSnack() {
                this.snackActivated = false;

                const fieldNameActivated = this.fieldNameActivated;

                if (fieldNameActivated) {
                    this.focusField(fieldNameActivated);
                    this.changeColorField(fieldNameActivated);
                }
            },
            toggleFieldBgColor(nameField, field) {
                this.fields[nameField].bgColor =
                    field.$dirty && field.$invalid
                        ? this.configs.fields.bgColor.error
                        : null;
            },
            changeColorField(nameField) {
                this.fields[nameField].bgColor = this.configs.fields.bgColor.error;
            },
            focusField(nameField) {
                this.$refs[nameField].focus();
            },
            validForm() {
                let valid = true;

                const fields = {
                    email: {
                        status: this.emailSuccessComputed,
                        message: forgotPasswordConfig.messages.email.errors.CHECK_EMAIL
                    }
                };

                if (!fields.email.status) {
                    this.fieldNameActivated = 'email';

                    valid = false;

                    this.activeSnack(fields.email.message);
                }

                return valid;
            },
            activeSnack(snackMessage, success) {
                this.snackActivated = true;
                this.snackSuccess = success;
                this.snackMessage = snackMessage;
            },
            filterMessageError(e) {
                const { message } = JSON.parse(JSON.stringify(e));

                if (message === 'Network Error') {
                    return {
                        field: null,
                        message: 'Erro de conexão com a API!'
                    };
                } else {
                    const { error } = e.response.data.status.payload;

                    const map = {
                        default: {
                            field: null,
                            message: 'Erro genérico!'
                        },
                        emailNotExists: {
                            field: null,
                            message: `O email ${this.user.email} não existe!`
                        }
                    };

                    const exists = map.hasOwnProperty(error);

                    return exists ? map[error] : map['default'];
                }
            },
            submitForm() {
                if (!this.validForm()) return;

                this.loadingActivated = true;

                this.$service.http[geralConfig.service.http]
                    .forgotPassword(this.user.email)
                    .then((_) => {
                        this.submitedForm = true;
                    })
                    .catch((e) => {
                        const { field, message } = this.filterMessageError(e);

                        this.activeSnack(message, false);

                        if (field) {
                            this.fieldNameActivated = field;
                        }
                    })
                    .finally((_) => {
                        this.loadingActivated = false;
                    });
            }
        }
    };
</script>

<style scoped>
    .btn-go-to {
        text-transform: none;
        font-weight: bold;
        letter-spacing: normal;
        background-color: #ffffff;
    }

    .field {
        padding: 0px !important;
        position: relative;
    }

    .close-field {
        cursor: pointer;
        position: absolute;
        right: 15px;
        top: -20px;
    }

    .box-submited {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        text-align: center;
    }

    .box-submited .info-title {
        margin-top: 10px;
    }

    .box-submited .info-msg {
        margin-top: 10px;
    }

    .box {
        display: flex;
        justify-content: center;
        margin-top: 30px;
    }
</style>
